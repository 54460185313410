<template>
  <div>
    <a-form-model ref="ruleForm" :model="directionalForm" v-bind="layout" class="text-left">
      <a-form-model-item label="投放时段">
        <a-radio-group v-model="directionalForm.timeInterval">
          <a-radio-button :value="0">不限</a-radio-button>
          <a-radio-button :value="1">指定时段</a-radio-button>
        </a-radio-group>
        <a-table
          v-show="directionalForm.timeInterval == 1"
          :columns="columns"
          :data-source="data"
          ref="timeRef"
          size="small"
          :height="80"
          class="directionalTable"
          :class="directionalForm.timeInterval == 0 ? 'disabled-style' : ''"
          bordered
          row-key="key"
          :pagination="false"
          :showHeader="false"
        >
        </a-table>
      </a-form-model-item>
      <a-form-model-item label="地域" class="text-left" disabled>
        <a-radio-group v-model="directionalForm.region">
          <a-radio-button :value="0">不限</a-radio-button>
          <a-radio-button :value="1">按行政区划分</a-radio-button>
        </a-radio-group>
        <div
          class="flexStyle"
          :class="directionalForm.region == 0 ? 'disabled-style' : ''"
          v-show="directionalForm.region == 1"
        >
          <div class="regionBox">
            <div class="regionBoxTitle">国家</div>
            <div class="height-100">
              <template v-if="areaOptions.length !== 0">
                <div class="regionTextBox" :key="country.value" v-for="country in areaOptions">
                  <div>
                    <a-checkbox
                      v-model="country.checked"
                      class="p-r-8"
                      @change="handleRegionClick('country', country)"
                    ></a-checkbox>
                    <span>{{ country.label }}</span>
                  </div>
                  <a-icon type="right" />
                </div>
              </template>
              <div class="regionTextBox" v-else>
                <div>
                  <span>暂无数据</span>
                </div>
              </div>
            </div>
          </div>
          <div class="regionBox">
            <div class="regionBoxTitle">省份</div>
            <div class="height-100">
              <template v-if="cityOptions.length !== 0">
                <!-- 全选 -->
                <div class="regionTextBox">
                  <div>
                    <a-checkbox
                      :indeterminate="provinceTag.length !== 0 && cityOptions.some((ditem) => ditem.checked !== true)"
                      :checked="cityOptions.every((ditem) => ditem.checked == true)"
                      @change="(e) => onCheckAllChange(e, 'provinceCheckAll')"
                    >全选</a-checkbox
                    >
                  </div>
                </div>
                <div class="regionTextBox" :key="province.value" v-for="province in cityOptions">
                  <div>
                    <a-checkbox
                      v-model="province.checked"
                      class="p-r-8"
                      @change="handleRegionClick('province', province)"
                    ></a-checkbox>
                    <span>{{ province.label }}</span>
                  </div>
                  <a-icon type="right" />
                </div>
              </template>
              <div class="regionTextBox" v-else>
                <div>
                  <span>暂无数据</span>
                </div>
              </div>
            </div>
          </div>
          <div class="regionBox">
            <div class="regionBoxTitle">城市</div>
            <div class="height-100">
              <template v-if="districtOptions.length !== 0">
                <!-- 全选 -->
                <div class="regionTextBox">
                  <div>
                    <!-- regionTag.length !==0 && regionTag.length!==districtOptions.length -->
                    <a-checkbox
                      :indeterminate="regionTag.length !== 0 && districtOptions.some((ditem) => ditem.checked !== true)"
                      :checked="districtOptions.every((ditem) => ditem.checked == true)"
                      @change="(e) => onCheckAllChange(e, 'cityCheckAll')"
                    >全选</a-checkbox
                    >
                  </div>
                </div>
                <div class="regionTextBox" :key="districtItem.value" v-for="districtItem in districtOptions">
                  <div>
                    <a-checkbox
                      v-model="districtItem.checked"
                      class="p-r-8"
                      @click="handleRegionClick('city', districtItem)"
                    ></a-checkbox>
                    <span>{{ districtItem.label }}</span>
                  </div>
                </div>
              </template>
              <div class="regionTextBox" v-else>
                <div>
                  <span>暂无数据</span>
                </div>
              </div>
            </div>
          </div>
          <div class="regionBox">
            <div class="checkedBox regionBoxTitle">
              <div class="">已选</div>
              <div class="clear-btn" @click="hanldeCloseTag('clearRegionTag', [])">清空</div>
            </div>
            <div class="height-100">
              <div class="checkedContent">
                <a-tag
                  class="tagItem"
                  v-for="item in regionTag"
                  :key="item.value"
                  closable
                  @close="hanldeCloseTag('regionTag', item)"
                >{{ item.label }}</a-tag
                >
              </div>
            </div>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="平台" class="text-left">
        <a-radio-group v-model="directionalForm.platform">
          <a-radio-button :value="-1">不限</a-radio-button>
          <a-radio-button v-if="directionalForm.platform == 0" :value="0">按平台划分</a-radio-button>
          <a-radio-button v-else :value="1">按平台划分</a-radio-button>
        </a-radio-group>
        <div v-show="directionalForm.platform !== -1">
          <a-radio-group v-model="directionalForm.platform">
            <a-radio :value="0">IOS</a-radio>
            <a-radio :value="1">Android</a-radio>
          </a-radio-group>
        </div>
      </a-form-model-item>
      <a-form-model-item label="设备类型" class="text-left">
        <a-radio-group v-model="directionalForm.equipment">
          <a-radio-button :value="-1">不限</a-radio-button>
          <a-radio-button v-if="directionalForm.equipment == 0" :value="0">按设备类型划分</a-radio-button>
          <a-radio-button v-else :value="1">按设备类型划分</a-radio-button>
        </a-radio-group>
        <div v-show="directionalForm.equipment !== -1">
          <a-radio-group v-model="directionalForm.equipment">
            <!-- <a-radio :value="-1">不限</a-radio> -->
            <a-radio :value="0">智能手机</a-radio>
            <a-radio :value="1">平板</a-radio>
          </a-radio-group>
        </div>
      </a-form-model-item>
      <a-form-model-item label="网络" class="text-left">
        <a-radio-group
          v-model="directionalForm.unlimitednetwork"
          @change="
            () => {
              directionalForm.network = []
            }
          "
        >
          <a-radio-button :value="0">不限</a-radio-button>
          <a-radio-button :value="undefined">按网络划分</a-radio-button>
        </a-radio-group>
        <!-- <a-radio-group v-model="directionalForm.unlimitednetwork" @change="()=> { directionalForm.network=[] }">
          <a-radio :value="0">不限</a-radio>
        </a-radio-group> -->
        <div v-show="directionalForm.unlimitednetwork !== 0">
          <a-checkbox-group
            v-model="directionalForm.network"
            @change="
              () => {
                directionalForm.unlimitednetwork = undefined
              }
            "
          >
            <a-checkbox :value="1">WI-FI</a-checkbox>
            <a-checkbox :value="2">2G</a-checkbox>
            <a-checkbox :value="3">3G</a-checkbox>
            <a-checkbox :value="4">4G</a-checkbox>
            <a-checkbox :value="5">5G</a-checkbox>
          </a-checkbox-group>
        </div>
      </a-form-model-item>
      <a-form-model-item label="运营商" class="text-left">
        <a-radio-group
          v-model="directionalForm.unlimitedoperator"
          @change="
            () => {
              directionalForm.operator = []
            }
          "
        >
          <a-radio-button :value="-1">不限</a-radio-button>
          <a-radio-button :value="undefined">按运营商划分</a-radio-button>
        </a-radio-group>
        <!-- <a-radio-group v-model="directionalForm.unlimitedoperator" @change="()=> { directionalForm.operator=[] }">
          <a-radio :value="-1">不限</a-radio>
        </a-radio-group> -->
        <div v-show="directionalForm.unlimitedoperator !== -1">
          <a-checkbox-group
            v-model="directionalForm.operator"
            @change="
              () => {
                directionalForm.unlimitedoperator = undefined
              }
            "
          >
            <a-checkbox :value="0">移动</a-checkbox>
            <a-checkbox :value="1">联通</a-checkbox>
            <a-checkbox :value="2">电信</a-checkbox>
          </a-checkbox-group>
        </div>
      </a-form-model-item>
      <a-form-model-item label="手机品牌" class="text-left">
        <a-radio-group v-model="directionalForm.moblieBrand">
          <a-radio-button :value="0">不限</a-radio-button>
          <a-radio-button :value="1">按品牌</a-radio-button>
        </a-radio-group>
        <div
          class="flexStyle"
          :class="directionalForm.moblieBrand == 0 ? 'disabled-style' : ''"
          v-show="directionalForm.moblieBrand == 1"
        >
          <div class="regionBox" style="flex: 3">
            <div class="regionBoxTitle">手机品牌</div>
            <div
              class="height-100"
              v-infinite-scroll="handleInfiniteOnLoad"
              :infinite-scroll-disabled="busy"
              :infinite-scroll-distance="10"
            >
              <template v-if="brandOptions.length !== 0">
                <!-- 全选 -->
                <div class="regionTextBox">
                  <div>
                    <a-checkbox
                      :indeterminate="brandTag.length !== 0 && brandTag.length !== brandOptions.length"
                      :checked="brandTag.length == brandOptions.length"
                      @change="(e) => onCheckAllChange(e, 'brandCheckAll')"
                    >全选</a-checkbox
                    >
                  </div>
                </div>
                <div class="regionTextBox" :key="item.id" v-for="item in brandOptions">
                  <div>
                    <a-checkbox v-model="item.checked" class="p-r-8" @click="handleRegionClick('brand', item)">{{
                      item.name
                    }}</a-checkbox>
                  </div>
                </div>
              </template>
              <div class="regionTextBox" v-else>
                <div>
                  <span>暂无数据</span>
                </div>
                <a-icon type="right" />
              </div>
            </div>
          </div>
          <div class="regionBox">
            <div class="checkedBox regionBoxTitle">
              <div class="">已选</div>
              <div class="clear-btn" @click="hanldeCloseTag('clearBrandTag', [])">清空</div>
            </div>
            <div class="height-100">
              <div class="checkedContent">
                <a-tag
                  class="tagItem"
                  v-for="(item, index) in brandTag"
                  :key="item + index"
                  closable
                  @close="hanldeCloseTag('brandTag', item)"
                >{{ item }}</a-tag
                >
              </div>
            </div>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="APP定向" class="text-left">
        <a-radio-group
          v-model="directionalForm.appAction"
          @change="
            () => {
              directionalForm.appActionList = []
            }
          "
        >
          <a-radio-button :value="0">不限</a-radio-button>
          <a-radio-button :value="1">APP分类</a-radio-button>
        </a-radio-group>
        <div v-show="directionalForm.appAction === 1">
          <a-radio-group v-model="directionalForm.appInclude" style="margin-top: 20px">
            <a-radio :value="0">定向已安装</a-radio>
            <a-radio :value="1">排除已安装</a-radio>
          </a-radio-group>
          <div class="flexStyle" style="margin-top: 10px">
            <div class="regionBox" style="flex: 3">
              <div class="regionBoxTitle">可选APP分类（{{ actionList.length }}）</div>
              <div
                class="height-100"
                v-infinite-scroll="handleInfiniteOnLoad"
                :infinite-scroll-disabled="busy"
                :infinite-scroll-distance="10"
              >
                <template v-if="actionList.length !== 0">
                  <!-- 全选 -->
                  <div class="regionTextBox">
                    <div>
                      <a-checkbox
                        :indeterminate="
                          directionalForm.appActionList.length !== 0 &&
                            directionalForm.appActionList.length !== actionList.length
                        "
                        :checked="directionalForm.appActionList.length == actionList.length"
                        @change="(e) => onCheckAllActionApp(e)"
                      >全选</a-checkbox
                      >
                    </div>
                  </div>
                  <div class="regionTextBox" :key="item.id" v-for="item in actionList">
                    <div>
                      <a-checkbox
                        :checked="directionalForm.appActionList.includes(item.id)"
                        class="p-r-8"
                        @click="handleClickActionAppItem(item)"
                      >{{ item.name }}</a-checkbox
                      >
                    </div>
                  </div>
                </template>
                <div class="regionTextBox" v-else>
                  <div>
                    <span>暂无数据</span>
                  </div>
                  <a-icon type="right" />
                </div>
              </div>
            </div>
            <div class="regionBox">
              <div class="checkedBox regionBoxTitle">
                <div>已选APP分类</div>
                <div class="clear-btn" @click="directionalForm.appActionList = []">清空</div>
              </div>
              <div class="height-100">
                <div class="checkedContent">
                  <template v-for="item in actionList">
                    <a-tag
                      v-if="directionalForm.appActionList.includes(item.id)"
                      class="tagItem"
                      :key="item.id"
                      closable
                      @close="handleClickActionAppItem(item)"
                    >{{ item.name }}</a-tag
                    >
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { getAreaList, getBrandList, getDirectionalSettingAppList } from '@/api/common'
import infiniteScroll from 'vue-infinite-scroll'
const data = [
  {
    key: '1'
  },
  {
    key: '2',
    name: '',
    titleName: '00:00 - 12:00'
  },
  {
    key: '3',
    time0: '0',
    time1: '1',
    time2: '2',
    time3: '3',
    time4: '4',
    time5: '5',
    time6: '6',
    time7: '7',
    time8: '8',
    time9: '9',
    time10: '10',
    time11: '11',
    time12: '12',
    time13: '13',
    time14: '14',
    time15: '15',
    time16: '16',
    time17: '17',
    time18: '18',
    time19: '19',
    time20: '20',
    time21: '21',
    time22: '22',
    time23: '23'
  },
  {
    key: '4',
    name: '星期一',
    time0: '0',
    time1: '1',
    time2: '2',
    time3: '3',
    time4: '4',
    time5: '5',
    time6: '6',
    time7: '7',
    time8: '8',
    time9: '9',
    time10: '10',
    time11: '11',
    time12: '12',
    time13: '13',
    time14: '14',
    time15: '15',
    time16: '16',
    time17: '17',
    time18: '18',
    time19: '19',
    time20: '20',
    time21: '21',
    time22: '22',
    time23: '23'
  },
  {
    key: '5',
    name: '星期二',
    time0: '0',
    time1: '1',
    time2: '2',
    time3: '3',
    time4: '4',
    time5: '5',
    time6: '6',
    time7: '7',
    time8: '8',
    time9: '9',
    time10: '10',
    time11: '11',
    time12: '12',
    time13: '13',
    time14: '14',
    time15: '15',
    time16: '16',
    time17: '17',
    time18: '18',
    time19: '19',
    time20: '20',
    time21: '21',
    time22: '22',
    time23: '23'
  },
  {
    key: '6',
    name: '星期三',
    time0: '0',
    time1: '1',
    time2: '2',
    time3: '3',
    time4: '4',
    time5: '5',
    time6: '6',
    time7: '7',
    time8: '8',
    time9: '9',
    time10: '10',
    time11: '11',
    time12: '12',
    time13: '13',
    time14: '14',
    time15: '15',
    time16: '16',
    time17: '17',
    time18: '18',
    time19: '19',
    time20: '20',
    time21: '21',
    time22: '22',
    time23: '23'
  },
  {
    key: '7',
    name: '星期四',
    time0: '0',
    time1: '1',
    time2: '2',
    time3: '3',
    time4: '4',
    time5: '5',
    time6: '6',
    time7: '7',
    time8: '8',
    time9: '9',
    time10: '10',
    time11: '11',
    time12: '12',
    time13: '13',
    time14: '14',
    time15: '15',
    time16: '16',
    time17: '17',
    time18: '18',
    time19: '19',
    time20: '20',
    time21: '21',
    time22: '22',
    time23: '23'
  },
  {
    key: '8',
    name: '星期五',
    time0: '0',
    time1: '1',
    time2: '2',
    time3: '3',
    time4: '4',
    time5: '5',
    time6: '6',
    time7: '7',
    time8: '8',
    time9: '9',
    time10: '10',
    time11: '11',
    time12: '12',
    time13: '13',
    time14: '14',
    time15: '15',
    time16: '16',
    time17: '17',
    time18: '18',
    time19: '19',
    time20: '20',
    time21: '21',
    time22: '22',
    time23: '23'
  },
  {
    key: '9',
    name: '星期六',
    time0: '0',
    time1: '1',
    time2: '2',
    time3: '3',
    time4: '4',
    time5: '5',
    time6: '6',
    time7: '7',
    time8: '8',
    time9: '9',
    time10: '10',
    time11: '11',
    time12: '12',
    time13: '13',
    time14: '14',
    time15: '15',
    time16: '16',
    time17: '17',
    time18: '18',
    time19: '19',
    time20: '20',
    time21: '21',
    time22: '22',
    time23: '23'
  },
  {
    key: '10',
    name: '星期日',
    time0: '0',
    time1: '1',
    time2: '2',
    time3: '3',
    time4: '4',
    time5: '5',
    time6: '6',
    time7: '7',
    time8: '8',
    time9: '9',
    time10: '10',
    time11: '11',
    time12: '12',
    time13: '13',
    time14: '14',
    time15: '15',
    time16: '16',
    time17: '17',
    time18: '18',
    time19: '19',
    time20: '20',
    time21: '21',
    time22: '22',
    time23: '23'
  },
  {
    key: '11',
    name: '可拖动鼠标选择时间段'
  }
]
export default {
  name: 'Directional',
  directives: { infiniteScroll },
  props: {
    planId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '60px',
        customRender: (text, row, index) => {
          if (index === 0) {
            return {
              children: (
                <div style="text-align: right; font-size: 12px; padding: 0px">
                  <span class="SelectedStyle"></span>
                  <span>已选</span>
                  <span class="OptionalStyle"></span>
                  <span>可选</span>
                </div>
              ),
              attrs: {
                colSpan: 25
              }
            }
          }
          if (index === 1) {
            // obj.attrs.rowSpan = 1
            return {
              children: <span>{text}</span>,
              attrs: {
                rowSpan: 2
              }
            }
          }
          // These two are merged into above cell
          if (index === 2) {
            return {
              children: <span>{text}</span>,
              attrs: {
                rowSpan: 0
              }
            }
          }
          if (index < 10) {
            return <span>{text}</span>
          }
          if (index === 10) {
            return {
              children: (
                <div style="text-align: center; font-size: 12px; padding: 4px;position:relative">
                  可拖动鼠标选择时间段
                  <div style="position:absolute;right:0;top:-5px">
                    <a-button
                      type="link"
                      onclick={() => {
                        this.releaseTime = []
                      }}
                    >
                      清空
                    </a-button>
                  </div>
                </div>
              ),
              attrs: {
                colSpan: 25
              }
            }
          }
        }
      },
      {
        title: '',
        colSpan: 12,
        dataIndex: 'time0',
        width: '20px',
        customCell: this.handleTableItem,
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            return {
              children: <div class="text-center color-666 p-8">00:00 - 12:00</div>,
              attrs: {
                colSpan: 12
              }
            }
            // obj.attrs.colSpan = 12
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        }
      },
      {
        title: '',
        colSpan: 0,
        dataIndex: 'time1',
        width: '20px',
        customCell: this.handleTableItem,
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        }
      },
      {
        title: '',
        width: '20px',
        colSpan: 12,
        dataIndex: 'time2',
        customCell: this.handleTableItem,
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        }
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time3',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            return {
              children: <div class="text-center color-666 p-8">12:00 - 23:00</div>,
              attrs: {
                colSpan: 12
              }
            }
            // obj.attrs.colSpan = 12
          }
          if (index === 3) {
            obj.attrs.rowSpan = 1
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
            obj.attrs.rowSpan = 1
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time4',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time5',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time6',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time7',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        width: '20px',
        colSpan: 0,
        dataIndex: 'time8',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time9',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        width: '20px',
        colSpan: 0,
        dataIndex: 'time10',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time11',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time12',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time13',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time14',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time15',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time16',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time17',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time18',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time19',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time20',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time21',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: '',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time22',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      },
      {
        title: 'time23',
        colSpan: 0,
        width: '20px',
        dataIndex: 'time23',
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {}
          }
          if (index === 0) {
            obj.attrs.colSpan = 0
          }
          if (index === 1) {
            obj.attrs.colSpan = 0
          }
          if (index === 2) {
            obj.children = <span class="text-center color-666 p-8">{value}</span>
          } else if (index === 10) {
            obj.attrs.colSpan = 0
          } else {
            obj.children = (
              <span
                class={`text-center ${
                  this.releaseTime.filter((item) => +item.dayOfWeek === +index - 2 && +item.hoursValue === +value)
                    .length > 0
                    ? 'cellActiveColor'
                    : ''
                }`}
              >
                {value}
              </span>
            )
          }
          return obj
        },
        customCell: this.handleTableItem
      }
    ]
    return {
      startItem: {},
      endItem: {},
      domPlace: { x: 0, y: 0 },
      busy: false,
      layout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 21 }
      },
      areaOptions: [],
      cityOptions: [],
      districtOptions: [],
      brandOptions: [],
      actionList: [],
      provinceTag: [],
      regionTag: [],
      brandTag: [],
      countryLabel: undefined,
      countryValue: undefined,
      provinceLabel: undefined,
      provinceValue: undefined,
      data,
      columns,
      // 投放时间
      releaseTime: [],
      directionalForm: {
        // 投放时段
        timeInterval: 0,
        // 地域
        region: 0,
        // 平台
        platform: -1,
        // 设备
        equipment: -1,
        // 网络
        network: [],
        // 网络 不限
        unlimitednetwork: 0,
        // 运营商
        operator: [],
        // 运营商 不限
        unlimitedoperator: -1,
        // 手机品牌
        moblieBrand: 0,
        // app定向
        appAction: 0,
        // 定向逻辑
        appInclude: 0,
        // app定向列表
        appActionList: []
      },
      isCheckedAllFlag: false,
      isDownFlag: false
    }
  },
  mounted () {
    this.getBrandListFn()
    this.getDirectionalSettingAppList()
    window.addEventListener('mousemove', this.handleMouseMove, true)
    window.addEventListener('mouseup', this.handleMouseUp, true)
  },
  created () {
    this.getAreaListFn()
  },
  destroyed () {
    window.removeEventListener('mousemove', this.handleMouseMove, true)
    window.removeEventListener('mouseup', this.handleMouseUp, true)
  },
  methods: {
    handleMouseUp (e) {
      const divDom = document.getElementsByClassName('drag_dom')[0]
      const body = document.body
      if (divDom) {
        divDom.style.display = 'none'
        setTimeout(() => {
          body.removeChild(divDom)
        }, 1000)
      }
      this.isDownFlag = false
    },
    handleMouseMove (e) {
      if (!this.isDownFlag) return
      let divDom = document.getElementsByClassName('drag_dom')[0]
      const body = document.body
      if (divDom) {
        body.removeChild(divDom)
      }
      divDom = document.createElement('div')
      divDom.className = 'drag_dom'
      divDom.style.background = 'aqua'
      divDom.style.opacity = '0.3'
      divDom.style.zIndex = '9999'
      divDom.style.position = 'fixed'
      const width = Math.abs(e.clientX - this.domPlace.x)
      const height = Math.abs(e.clientY - this.domPlace.y)
      divDom.style.width = width - 4 + 'px'
      divDom.style.height = height - 4 + 'px'
      const startPlaceX = e.clientX - this.domPlace.x > 0 ? this.domPlace.x : e.clientX
      const startPlaceY = e.clientY - this.domPlace.y > 0 ? this.domPlace.y : e.clientY
      divDom.style.left = startPlaceX + 2 + 'px'
      divDom.style.top = startPlaceY + 2 + 'px'
      body.appendChild(divDom)
    },
    // 获取地区
    async getAreaListFn (data) {
      const res = await getAreaList(data)
      if (res.code === 200 && res.data) {
        const tempData = res.data || []
        tempData.forEach((item) => {
          item.children.forEach((ele) => {
            ele.checked = false
          })
        })
        this.areaOptions = tempData
      }
    },
    // 获取手机品牌
    async getBrandListFn (data) {
      const res = await getBrandList(data)
      if (res.code === 200 && res.data) {
        const tempData = res.data
        this.brandOptions = tempData
      }
    },
    async getDirectionalSettingAppList () {
      const res = await getDirectionalSettingAppList()
      if (res.code === 200 && res.data) {
        this.actionList = res.data
      }
    },
    // 省市区点击
    handleRegionClick (type, data, checked) {
      if (type === 'country') {
        this.areaOptions = this.areaOptions.map((item) => {
          item.checked = false
          return item
        })
        this.cityOptions = data.children
        this.countryLabel = data.label
        this.countryValue = data.value
        data.checked = true
      } else if (type === 'province') {
        this.isCheckedAllFlag = this.cityOptions.every((cityem) => cityem.checked === true)
        this.cityOptions = JSON.parse(JSON.stringify(this.cityOptions))
        this.provinceLabel = data.label
        this.provinceValue = data.value
        // const { checked } = this.cityOptions.find(cuitem => cuitem.value === data.value)
        // if (data.checked && this.isCheckedAllFlag) {
        //   // 判断是否被选中了
        //   this.provinceTag = this.provinceTag.filter(pitem => pitem.id !== data.value)
        //   const NewcityOptions = this.cityOptions.filter(citem => citem.value !== data.value)
        //   this.regionTag = []
        //   NewcityOptions.length !== 0 && NewcityOptions.forEach(pele => {
        //     pele.children.length !== 0 && pele.children.forEach(pcItme => {
        //       // pcItme.checked=
        //       this.regionTag.push({ label: `${this.countryLabel}-${pcItme.label}-${pele.label}`, id: pele.value, value: `${this.countryValue}-${pcItme.label}-${pele.value}` })
        //     })
        //   })
        // } else
        this.districtOptions = data.children
        if (!data.checked) {
          this.provinceTag = this.provinceTag.filter((pitem) => pitem.id !== data.value)
          data.children.length !== 0 &&
            data.children.forEach((ditme) => {
              ditme.checked = false
              this.regionTag = this.regionTag.filter((reitem) => reitem.id !== ditme.value)
            })
        } else {
          this.provinceTag.push({ label: data.label, id: data.value, value: data.value })
          if (this.isCheckedAllFlag) {
            // const lengthNum = this.cityOptions.filter(citem => citem.checked).length
            data.children.length !== 0 &&
              data.children.forEach((pcItme) => {
                pcItme.checked = true
                this.regionTag.unshift({
                  label: `${this.countryLabel}-${pcItme.label}-${data.label}`,
                  id: data.value,
                  value: `${this.countryValue}-${pcItme.value}-${data.value}`
                })
              })
          } else {
            data.children.length !== 0 &&
              data.children.forEach((ditme) => {
                ditme.checked = false
                this.regionTag = this.regionTag.filter((reitem) => reitem.id !== ditme.value)
              })
          }
        }
        // this.cityOptions = JSON.parse(JSON.stringify(this.cityOptions))
      } else if (type === 'city') {
        this.districtOptions = this.districtOptions.map((item) => {
          return item
        })
        if (data.checked) {
          // 判断是否被选中了 如果已选中的就做移除操作
          this.regionTag = this.regionTag.filter((ritem) => ritem.id !== data.value)
        } else if (!data.checked && this.regionTag.filter((ele) => ele.id === data.value).length === 0) {
          // 判断是否被选中了 如果没有选中的就做添加操作
          this.regionTag.push({
            label: `${this.countryLabel}-${this.provinceLabel}-${data.label}`,
            id: data.value,
            value: `${this.countryValue}-${this.provinceValue}-${data.value}`
          })
        }
        data.checked = !data.checked
      } else if (type === 'brand') {
        if (data.checked && this.brandTag.includes(data.name)) {
          // 判断是否被选中了 如果已选中的就做移除操作
          this.brandTag = this.brandTag.filter((bitem) => bitem !== data.name)
        } else if (!data.checked && this.brandTag.filter((ele) => ele === data.name).length === 0) {
          // 判断是否被选中了 如果没有选中的就做添加操作
          this.brandTag.push(data.name)
        }
      }
    },
    // 关闭tag
    hanldeCloseTag (type, item) {
      if (type === 'regionTag') {
        this.regionTag = this.regionTag.filter((ritem) => ritem.id !== item.id)
        this.districtOptions = this.districtOptions.map((ele) => {
          ele.checked = ele.checked && item.id !== ele.value
          return ele
        })
      } else if (type === 'brandTag') {
        this.brandTag = this.brandTag.filter((bitem) => bitem !== item)
        this.brandOptions = this.brandOptions.map((ele) => {
          ele.checked = ele.checked && item !== ele.name
          return ele
        })
      } else if (type === 'clearRegionTag') {
        this.regionTag = []
        this.provinceTag = []
        this.districtOptions = this.districtOptions.map((ele) => {
          ele.checked = false
          return ele
        })
        this.cityOptions = this.cityOptions.map((ele) => {
          ele.checked = false
          ele.children.length !== 0 &&
            ele.children.forEach((childitme) => {
              childitme.checked = false
            })
          return ele
        })
      } else if (type === 'clearBrandTag') {
        this.brandTag = []
        this.brandOptions = this.brandOptions.map((ele) => {
          ele.checked = false
          return ele
        })
      }
    },
    handleClickActionAppItem (item) {
      const hasChecked = this.directionalForm.appActionList.indexOf(item.id)
      if (hasChecked !== -1) {
        this.directionalForm.appActionList.splice(hasChecked, 1)
      } else {
        this.directionalForm.appActionList.push(item.id)
      }
    },
    onCheckAllActionApp (e) {
      if (e.target.checked) {
        this.directionalForm.appActionList = this.actionList.map((item) => item.id)
      } else {
        this.directionalForm.appActionList = []
      }
    },
    // 全选(所有页面全选都公用一个方法)
    onCheckAllChange (e, type) {
      if (type === 'provinceCheckAll') {
        // 判断用户是否点击全选
        this.isCheckedAllFlag = e.target.checked
        if (e.target.checked) {
          // 全选
          this.regionTag = []
          this.cityOptions = this.cityOptions.map((item) => {
            item.checked = e.target.checked
            this.provinceTag.push({ label: item.label, id: item.value, value: item.value })
            item.children &&
              item.children.length !== 0 &&
              item.children.forEach((childrenItem) => {
                childrenItem.checked = true
                this.regionTag.push({
                  label: `${this.countryLabel}-${item.label}-${childrenItem.label}`,
                  id: childrenItem.value,
                  value: `${this.countryValue}-${item.value}-${childrenItem.value}`
                })
              })
            this.districtOptions = item.children
            return item
          })
        } else {
          // 取消全选
          this.cityOptions = this.cityOptions.map((item) => {
            item.checked = false
            return item
          })
          this.provinceTag = []
          this.regionTag = []
          this.districtOptions = this.districtOptions.map((ele) => {
            ele.checked = false
            return ele
          })
        }
      } else if (type === 'cityCheckAll') {
        if (e.target.checked) {
          this.districtOptions = this.districtOptions.map((item) => {
            item.checked = e.target.checked
            this.regionTag.push({
              label: `${this.countryLabel}-${this.provinceLabel}-${item.label}`,
              id: item.value,
              value: `${this.countryValue}-${this.provinceValue}-${item.value}`
            })
            return item
          })
        } else {
          this.regionTag = []
          this.districtOptions = this.districtOptions.map((ele) => {
            ele.checked = false
            return ele
          })
        }
      } else if (type === 'brandCheckAll') {
        if (e.target.checked) {
          this.brandOptions = this.brandOptions.map((ele) => {
            ele.checked = e.target.checked
            this.brandTag.push(ele.name)
            return ele
          })
        } else {
          this.brandTag = []
        }
      }
    },
    // 回显省市区
    echoCityOptions (arr) {
      this.areaOptions.forEach((itme) => {
        if (itme.value === arr[0]) {
          itme.checked = true
          this.countryLabel = itme.label
          this.countryValue = itme.value
          this.provinceTag.push({ label: itme.label, id: itme.value, value: itme.value })
          this.cityOptions = itme.children.map((ele) => {
            if (ele.value === arr[1]) {
              ele.checked = true
              this.provinceLabel = ele.label
              this.provinceValue = ele.value
              this.districtOptions = ele.children.map((ele1) => {
                if (ele1.value === arr[2]) {
                  ele1.checked = true
                  this.regionTag.push({
                    label: `${this.countryLabel}-${this.provinceLabel}-${ele1.label}`,
                    id: ele1.value,
                    value: `${this.countryValue}-${this.provinceValue}-${ele1.value}`
                  })
                }
                return ele1
              })
            }
            return ele
          })
        }
      })
    },
    // 点击表格单元格
    handleTableItem (record, rowIndex, i) {
      return {
        style: {
          color: 'transparent',
          'user-select': 'none',
          padding: rowIndex === 2 ? 'px' : '0px'
        },
        on: {
          mousedown: (evet, i) => {
            this.isDownFlag = true
            this.startItem = {
              day: rowIndex - 2,
              hour: +evet.target.innerText
            }
            this.domPlace = { x: evet.clientX, y: evet.clientY }
          },
          mouseenter: (evet) => {},
          mouseup: (evet) => {
            this.endItem = {
              day: rowIndex - 2,
              hour: +evet.target.innerText
            }
            this.handleSetReleaseTime()
            this.isDownFlag = false
          }
        }
      }
    },
    handleInfiniteOnLoad () {},
    handleSetReleaseTime () {
      const start = {
        x: this.startItem.hour,
        y: this.startItem.day
      }
      const end = {
        x: this.endItem.hour,
        y: this.endItem.day
      }
      const maxX = start.x - end.x > 0 ? start.x : end.x
      const minX = start.x - end.x > 0 ? end.x : start.x
      const maxY = start.y - end.y > 0 ? start.y : end.y
      const minY = start.y - end.y > 0 ? end.y : start.y
      for (let nowX = minX; nowX <= maxX; nowX++) {
        for (let nowY = minY; nowY <= maxY; nowY++) {
          const hasItem = this.releaseTime.find((item) => {
            return item.dayOfWeek === '' + nowY && item.hoursValue === '' + nowX
          })
          if (!hasItem) {
            this.releaseTime.push({
              dayOfWeek: '' + nowY,
              hoursValue: '' + nowX
            })
          } else {
            let cur = 0
            this.releaseTime.forEach((item, i) => {
              if (item.dayOfWeek === '' + nowY && item.hoursValue === '' + nowX) {
                cur = i
              }
            })
            this.releaseTime.splice(cur, 1)
          }
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.flexStyle {
  display: flex;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.p-r-8 {
  padding-right: 0px;
}
.font-size-13 {
  font-size: 13px;
}
.height-100 {
  height: 160px;
  overflow-y: scroll;
  /*滚动条样式*/
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 5px fade(@primary-color, 20%);
    background: fade(@primary-color, 20%);
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px fade(@primary-color, 20%);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
  .anticon-right {
    line-height: 40px !important;
  }
}
.clear-btn {
  color: #3467fe;
}
.regionBox {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  flex: 1;
  margin-right: 10px;
  :hover {
    cursor: pointer;
  }
  .regionBoxTitle {
    // background: red;
    padding: 0px 15px;
    font-size: 13px;
    border-bottom: 1px solid #e7e7e7;
  }
  .checkedBox {
    display: flex;
    justify-content: space-between;
  }
  .checkedContent {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    .tagItem {
      margin: 6px 0px;
      display: flex;
      justify-content: space-between;
      i {
        line-height: 2 !important;
      }
    }
  }

  .regionTextBox {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    vertical-align: middle;
    font-size: 14px;
  }
}
.directionalTable {
  ::v-deep .ant-table-content > .ant-table-body {
    margin: 0 !important;
  }
}
.SelectedStyle {
  background: #3467fe;
  display: inline-block;
  width: 25px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}
.disabled-style {
  pointer-events: none;
  opacity: 0.5;
}
.OptionalStyle {
  display: inline-block;
  border: 1px solid #e7e7e7;
  width: 25px;
  height: 10px;
  border-radius: 5px;
  margin: 0px 5px;
}
.color-666 {
  color: #666;
}
.p-8 {
  padding: 0px;
}
.cellActiveColor {
  background: #3467fe;
  color: transparent;
  display: inline-block;
  width: 100%;
  height: 37px;
}
::v-deep.ant-radio-button-wrapper-checked {
  color: #456bff;
  background: fade(@primary-color, 15%);
  border: 1px solid #456bff;
}
</style>
